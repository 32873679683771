import Vue from 'vue';
import VueRouter from 'vue-router';
import HomePage from '../views/HomePage.vue';
//import EditProperty from "../views/EditProperty.vue";
//import NewProperty from "../views/NewProperty.vue";
import SearchBar from '../views/SearchBar.vue';
import viewProperty from '../views/viewProperty.vue';
import AboutPage from '../views/AboutPage.vue';
import firebase from 'firebase';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage,
  },
  {
    path: '/search',
    name: 'search',
    component: SearchBar,
  },
  {
    path: '/new',
    name: 'new',
    component: () => import('../views/NewProperty.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LogIn.vue'),
  },
  {
    path: '/edit',
    name: 'edit',
    component: () =>
      import(/* webpackChunkName: "NewProperty" */ '../views/EditProperty.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/viewProperty/:id',
    name: 'viewProperty',
    component: viewProperty,
  },
  {
    path: '/About',
    name: 'About',
    component: AboutPage,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const isAuthenticated = firebase.auth().currentUser;
  if (requiresAuth && !isAuthenticated) {
    next('/login');
  } else {
    next();
  }
});
export default router;
