<template>
  <v-main id="search">
    <v-container fluid>
      <v-row class="mt-md-5">
        <v-col md="1" offset-md="8"
          ><v-select
            :items="itemsTable"
            item-text="label"
            item-value="value"
            outlined
            dense
            :label="$t('sort')"
            v-model="sortValue"
            @change="sort"
          ></v-select>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col
          md="3"
          sm="12"
          class="mb-3"
          v-for="estate in estates"
          :key="estate.id"
        >
          <v-hover v-slot="{ hover }">
            <v-card
              :elevation="hover ? 20 : 2"
              class="mx-auto"
              max-width="344"
              tile
              @click="viewResult(estate.id)"
            >
              <v-img :src="estate.thumbnailUrl" height="180px"></v-img>

              <v-card-title
                class="pa-2 mb-3 d-sm-flex justify-center text-subtitle-1"
                style="color: white; background-color: black"
              >
                {{ `${language == 'en' ? estate.title : estate.titleGR}` }}
              </v-card-title>
              <v-row class="mt-3">
                <v-col cols="4" justify="center" class="pt-2">
                  <v-col
                    cols="12"
                    class="d-flex justify-center"
                    style="padding: 0px"
                  >
                    <v-img
                      max-width="32"
                      :src="require('@/assets/icons/location.svg')"
                      contain
                    />
                  </v-col>
                  <v-col cols="12" class="d-flex justify-center">
                    <div class="text-subtitle-3">
                      {{ $t(estate.adress) }}
                    </div>
                  </v-col>
                </v-col>
                <v-col cols="4" justify="center" class="pt-2">
                  <v-col
                    cols="12"
                    class="d-flex justify-center"
                    style="padding: 0px"
                  >
                    <v-img
                      v-if="estate.propertyType == 'House'"
                      max-width="32"
                      :src="require('@/assets/icons/basement.svg')"
                      contain
                    />
                    <v-img
                      v-else
                      max-width="32"
                      :src="require('@/assets/icons/plotSize.svg')"
                      contain
                    />
                  </v-col>
                  <v-col cols="12" class="d-flex justify-center">
                    <div class="text-subtitle-2">
                      {{ $t(estate.propertyType) }}
                    </div>
                  </v-col>
                </v-col>
                <v-col cols="4" justify="center" class="pt-2">
                  <v-col
                    cols="12"
                    class="d-flex justify-center"
                    style="padding: 0px"
                  >
                    <v-img
                      max-width="32"
                      :src="require('@/assets/icons/price.svg')"
                      contain
                    />
                  </v-col>
                  <v-col cols="12" class="d-flex justify-center">
                    <div class="text-subtitle-2">
                      {{ estate.price.toLocaleString(undefined, {}) }}
                    </div>
                  </v-col>
                </v-col>
              </v-row>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>
<script>
export default {
  name: 'SearchBar',
  data: () => ({
    items: ['Ascending', 'Descending'],
    sortValue: null,
  }),

  computed: {
    estates() {
      let results = this.$store.getters.viewProperties;
      return results;
    },
    language() {
      return this.$i18n.locale;
    },
    itemsTable() {
      return [
        {
          label: this.$t('Ascending'),
          value: 'Ascending',
        },
        {
          label: this.$t('Descending'),
          value: 'Descending',
        },
      ];
    },
  },
  methods: {
    viewResult(did) {
      this.$store.commit('searchClicked', did);
      // this.$router.push({ name: "viewProperty" });]
      this.$router.replace({
        name: 'viewProperty',
        params: { id: did },
      });
    },

    sort() {
      if (this.sortValue == 'Ascending') this.$store.commit('sortAsc');
      else this.$store.commit('sortDesc');
    },
  },
};
</script>

<style scoped>
#search {
  background-color: rgb(223, 243, 239) !important;
}
</style>
