<template>
  <v-app class="myFont">
    <Navbar />
    <router-view></router-view>
  </v-app>
</template>

<script>
import Navbar from '@/components/NavBar';
export default {
  name: 'App',
  components: { Navbar },

  data: () => ({
    //
  }),
  mounted() {
    this.$store.dispatch('getFeatured');
  },
};
</script>

<style>
.text-subtitle-3 {
  white-space: nowrap;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

.v-list-item--dense .v-list-item__title,
.v-list-item--dense .v-list-item__subtitle,
.v-list--dense .v-list-item .v-list-item__title,
.v-list--dense .v-list-item .v-list-item__subtitle {
  font-size: 1.3125rem !important;
  font-weight: 500;
  line-height: 1rem;
  padding: 8 !important;
  overflow: visible !important;
}
.myFont {
  background: rgb(241, 239, 236) !important;
}
</style>
