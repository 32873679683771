<template>
  <v-main id="home">
    <v-container fluid ma-0 pa-0 fill-height>
      <v-row wrap justify="center" class="mt-12 mb-md-10">
        <v-col cols="auto" sm="4" md="2">
          <v-select
            class="mr-md-4"
            style="opacity: 85%"
            :items="$t('type')"
            item-text="value"
            item-value="text"
            :label="$t('typeLabel')"
            v-model="contractSelected"
            solo
          >
          </v-select>
        </v-col>
        <v-col cols="auto" sm="4" md="2">
          <v-select
            class="mr-md-4"
            style="opacity: 85%"
            :items="$t('propertyType')"
            item-text="value"
            item-value="text"
            v-model="propertyTypeSelected"
            solo
          >
          </v-select>
        </v-col>
        <v-col cols="auto" sm="4" md="2">
          <v-select
            required
            style="opacity: 85%"
            class="mr-md-4"
            :items="$t('area')"
            :label="$t('areaLabel')"
            item-text="value"
            item-value="text"
            v-model="adressSelected"
            solo
          >
          </v-select>
        </v-col>
        <v-col cols="auto" sm="12" md="1" class="d-sm-flex justify-center">
          <v-btn
            large
            tile
            class="ml-md-10"
            @click="clicked"
            style="opacity: 85%"
          >
            <div class="font-weight-black">{{ $t('search') }}</div>
          </v-btn>
        </v-col>
      </v-row>

      <Featured />
    </v-container>
  </v-main>
</template>

<script>
import Featured from '@/components/FeaturedItem';
export default {
  name: 'HomePage',
  components: { Featured },
  data() {
    return {
      contractSelected: 'Buy',
      propertyTypeSelected: 'Plot',
      adressSelected: null,
      loader: null,
    };
  },

  methods: {
    clicked() {
      return this.$store
        .dispatch('setProperties', {
          contract: this.contractSelected,
          type: this.propertyTypeSelected,
          adress: this.adressSelected,
        })
        .then(() => {
          let results = this.$store.getters.viewProperties;
          if (results.length == 0) {
            this.$store.dispatch('getAllProperties');
            return this.$router.push({ name: 'search' });
          } else return this.$router.push({ name: 'search' });
        });
    },
  },
};
</script>

<style scoped>
#home {
  background-image: url('../assets/homepage.jpg');
  background-position: top;
  background-size: cover;
}
</style>
