<template>
  <v-container fluid>
    <v-row justify="center" align="center">
      <!-- featured no 1 -->
      <v-hover v-slot="{ hover }" v-if="featured1">
        <v-col md="4" sm="10" class="ml-md-15 my-10 d-sm-flex justify-center">
          <v-card
            :elevation="hover ? 20 : 2"
            class="mx-auto"
            max-width="420"
            tile
            @click="viewFeatured(featured1)"
            style="opacity: 95%"
          >
            <v-img :src="featured1.thumbnailUrl" height="180px"></v-img>

            <v-card-title
              class="pa-1 mb-3 d-sm-flex justify-center text-subtitle-1"
              style="color: white; background-color: black"
            >
              {{ `${language == 'en' ? featured1.title : featured1.titleGR}` }}
            </v-card-title>
            <v-row>
              <v-col cols="4" justify="center" align class="pt-2">
                <v-col
                  cols="12"
                  class="d-flex justify-center"
                  style="padding: 0px"
                >
                  <v-img
                    max-width="32"
                    :src="require('@/assets/icons/location.svg')"
                    contain
                  />
                </v-col>
                <v-col cols="12" class="d-flex justify-center">
                  <div class="text-subtitle-3">
                    {{ $t(featured1.adress) }}
                  </div>
                </v-col>
              </v-col>
              <v-col cols="4" justify="center" class="pt-2">
                <v-col
                  cols="12"
                  class="d-flex justify-center"
                  style="padding: 0px"
                >
                  <v-img
                    v-if="featured1.propertyType == 'House'"
                    max-width="32"
                    :src="require('@/assets/icons/basement.svg')"
                    contain
                  />
                  <v-img
                    v-else
                    max-width="32"
                    :src="require('@/assets/icons/plotSize.svg')"
                    contain
                  />
                </v-col>
                <v-col cols="12" class="d-flex justify-center">
                  <div class="text-subtitle-2">
                    {{ $t(featured1.propertyType) }}
                  </div>
                </v-col>
              </v-col>
              <v-col cols="4" justify="center" class="pt-2">
                <v-col
                  cols="12"
                  class="d-flex justify-center"
                  style="padding: 0px"
                >
                  <v-img
                    max-width="32"
                    :src="require('@/assets/icons/price.svg')"
                    contain
                  />
                </v-col>
                <v-col cols="12" class="d-flex justify-center">
                  <div class="text-subtitle-2">
                    {{ featured1.price.toLocaleString(undefined, {}) }}€
                  </div>
                </v-col>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-hover>
      <!-- featured no 2 -->
      <v-hover v-slot="{ hover }" v-if="featured2">
        <v-col md="4" sm="10" class="ml-md-15 d-sm-flex justify-center">
          <v-card
            :elevation="hover ? 20 : 2"
            class="mx-auto"
            max-width="420"
            tile
            @click="viewFeatured(featured2)"
            style="opacity: 95%"
          >
            <v-img :src="featured2.thumbnailUrl" height="180px"></v-img>

            <v-card-title
              class="pa-1 mb-3 d-sm-flex justify-center text-subtitle-1"
              style="color: white; background-color: black"
            >
              {{ `${language == 'en' ? featured2.title : featured2.titleGR}` }}
            </v-card-title>
            <v-row>
              <v-col cols="4" justify="center" class="pt-2">
                <v-col
                  cols="12"
                  class="d-flex justify-center"
                  style="padding: 0px"
                >
                  <v-img
                    max-width="32"
                    :src="require('@/assets/icons/location.svg')"
                    contain
                  />
                </v-col>
                <v-col cols="12" class="d-flex justify-center">
                  <div class="text-subtitle-3">
                    {{ $t(featured2.adress) }}
                  </div>
                </v-col>
              </v-col>
              <v-col cols="4" justify="center" class="pt-2">
                <v-col
                  cols="12"
                  class="d-flex justify-center"
                  style="padding: 0px"
                >
                  <v-img
                    v-if="featured2.propertyType == 'House'"
                    max-width="32"
                    :src="require('@/assets/icons/basement.svg')"
                    contain
                  />
                  <v-img
                    v-else
                    max-width="32"
                    :src="require('@/assets/icons/plotSize.svg')"
                    contain
                  />
                </v-col>
                <v-col cols="12" class="d-flex justify-center">
                  <div class="text-subtitle-2">
                    {{ $t(featured2.propertyType) }}
                  </div>
                </v-col>
              </v-col>
              <v-col cols="4" justify="center" class="pt-2">
                <v-col
                  cols="12"
                  class="d-flex justify-center"
                  style="padding: 0px"
                >
                  <v-img
                    max-width="32"
                    :src="require('@/assets/icons/price.svg')"
                    contain
                  />
                </v-col>
                <v-col cols="12" class="d-flex justify-center">
                  <div class="text-subtitle-2">
                    {{ featured2.price.toLocaleString(undefined, {}) }}€
                  </div>
                </v-col>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-hover>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({}),
  computed: {
    featured1() {
      return this.$store.getters.viewFeatured1;
    },

    featured2() {
      return this.$store.getters.viewFeatured2;
    },

    language() {
      return this.$i18n.locale;
    },
  },
  methods: {
    viewFeatured(featured) {
      this.$store.commit('bookmarkSet', featured);
      // this.$router.push({ name: "viewProperty" });]
      this.$router.replace({
        name: 'viewProperty',
        params: { id: featured.id },
      });
    },
  },
};
</script>

<style scoped>
h6 {
  color: #e7ebe9;
}
</style>
