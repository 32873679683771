import Vue from 'vue';
import Vuex from 'vuex';
import { db } from '@/main';
import createPersistedState from 'vuex-persistedstate';
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    searchClicked: [],
    clickedProperty: {},
    bookmark: {},
    featured: [],
    i: 0,
    j: 1,
    timeOnPage: 0,
  },
  plugins: [createPersistedState()],
  mutations: {
    addToArray(state, payload) {
      return state.searchClicked.push(payload);
    },
    addToFeatured(state, payload) {
      return state.featured.push(payload);
    },
    clearArray(state) {
      return (state.searchClicked = []);
    },
    clearFeatured(state) {
      state.i = 0;
      state.j = 1;
      state.timeOnPage = 0;
      return (state.featured = []);
    },
    selectFeatured(state) {
      return (state.featured = state.featured.sort(() => 0.5 - Math.random()));
    },
    searchClicked(state, id) {
      return (state.clickedProperty = state.searchClicked.find(
        (property) => property.id == id
      ));
    },
    bookmarkSet(state, data) {
      return (state.clickedProperty = data);
    },
    sortAsc(state) {
      return state.searchClicked.sort(function (a, b) {
        return a.price - b.price;
      });
    },
    sortDesc(state) {
      return state.searchClicked.sort(function (a, b) {
        return b.price - a.price;
      });
    },
    timerN(state) {
      {
        if (state.j == 1) {
          let jInterval1 = setInterval(() => {
            if (state.j + 1 < state.featured.length) state.j = state.j + 2;
            else {
              clearInterval(jInterval1);
            }

            if (
              state.j + 1 == state.featured.length ||
              state.j == state.featured.length
            ) {
              let jInterval2 = setInterval(() => {
                if (state.j > 1) state.j = state.j - 2;
                else {
                  clearInterval(jInterval2);
                }
              }, 10000);
            }
          }, 10000);
        }
        if (state.i == 0) {
          let iInterval1 = setInterval(() => {
            if (state.i + 2 < state.featured.length) state.i = state.i + 2;
            if (
              state.i + 2 == state.featured.length ||
              state.i + 1 == state.featured.length
            ) {
              let iInterval2 = setInterval(() => {
                if (state.i > 0) {
                  state.i = state.i - 2;
                } else {
                  clearInterval(iInterval2);
                }
                clearInterval(iInterval1);
              }, 10000);
            }
          }, 10000);
        }
      }
    },
  },
  actions: {
    async setProperties({ commit }, params) {
      commit('clearArray');

      let snapshot = await db
        .collection('estate')
        .where('adress', '==', params.adress)
        .where('contract', '==', params.contract)
        .where('propertyType', '==', params.type)
        .get();
      snapshot.forEach((doc) => {
        let appData = doc.data();
        appData.id = doc.id;
        commit('addToArray', appData);
      });
    },
    async getAllProperties({ commit }) {
      commit('clearArray');

      let snapshot = await db.collection('estate').get();
      snapshot.forEach((doc) => {
        let appData = doc.data();
        appData.id = doc.id;
        commit('addToArray', appData);
      });
    },
    async getFeatured({ commit, state }) {
      commit('clearFeatured');
      let snapshot = await db
        .collection('estate')
        .where('featured', '==', true)
        .get();
      snapshot.forEach((doc) => {
        let appData = doc.data();
        appData.id = doc.id;
        commit('addToFeatured', appData);
      });
      commit('selectFeatured');
      setTimeout(() => {
        commit('timerN');
      }, 3500);

      let refresh = setInterval(() => {
        commit('timerN');
        state.timeOnPage++;
        if (state.timeOnPage == 3) clearInterval(refresh);
      }, state.featured.length * 9000);
    },
    async findBookmarked({ commit }, params) {
      let snapshot = await db.collection('estate').doc(params.id).get();
      commit('bookmarkSet', snapshot.data());
    },
  },

  modules: {},
  getters: {
    viewProperties(state) {
      return state.searchClicked;
    },
    viewClicked(state) {
      return state.clickedProperty;
    },
    viewFeatured1(state) {
      return state.featured[state.i];
    },
    viewFeatured2(state) {
      return state.featured[state.j];
    },
  },
});
