import Vue from "vue";
import Vuetify from "vuetify/lib";
import el from "vuetify/es5/locale/el";
import en from "vuetify/es5/locale/en";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { en, el },
    current: "el",
  },
  theme: {
    themes: {
      light: {
        primary: "#c5dfd6",
        secondary: "#3f001a",
        accent: "#8c9eff",
        error: "#b71c1c",
      },
    },
  },
});
