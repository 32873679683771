<template>
  <v-app-bar app color="primary" class="px-0">
    <v-btn text max-width="84px" height="110%" class="mr-5 pl-0">
      <router-link to="/">
        <v-img
          alt="Real Estate Logo"
          :src="require('@/assets/Logo Manios.png')"
          contain
          transition="scale-transition"
          width="150"
          height="50"
        />
      </router-link>
    </v-btn>

    <v-toolbar-title
      class="font-weight-medium text-md-h5 text-caption text-wrap ml-md-12"
      >{{ $t('navTitle') }}</v-toolbar-title
    >
    <v-spacer></v-spacer>

    <v-spacer></v-spacer>
    <router-link to="/About">
      <v-btn
        text
        height="105%"
        class="font-weight-black body-2 mr-12 d-none d-md-block"
      >
        {{ $t('navAbout') }}</v-btn
      >

      <v-icon class="d-flex d-sm-none mr-2">mdi-information</v-icon>
    </router-link>
    <!-- language select -->
    <v-col md="1" sm="2">
      <v-select
        append-icon=""
        dense
        hide-selected
        single-line
        reverse
        class="mt-md-3 mt-3"
        style="width: 35px; font-size: 28px"
        full-width
        :label="items.src"
        :items="items"
        :value="$i18n.locale"
        item-text="src"
        item-value="name"
        v-model="$i18n.locale"
      >
        <template v-slot:selection="{ item }">
          <img :src="item.src" />
        </template>
        <template v-slot:item="{ item }">
          <img :src="item.src" />
        </template>
      </v-select>
    </v-col>
    <!-- <v-btn text height="110%" class="pr-0" x-small>
      <v-icon>mdi-phone </v-icon>
      <div class="text-md-subtitle-1 d-md-flex d-none">+306985033015</div>
    </v-btn> -->
    <div md="1">
      <v-list-item>
        <v-list-item-title class="my-2 text-button">
          <span class="d-flex justify-center"
            ><v-icon dense left>mdi-phone</v-icon> +306985033015
          </span>
          <v-divider></v-divider>
          <v-btn class="mt-1" small @click="mail">
            <v-icon class="mr-1">mdi-email</v-icon>
            <span class="text-caption text-lowercase font-weight-medium"
              >info@naxestate.com</span
            >
          </v-btn>
        </v-list-item-title>
      </v-list-item>
    </div>
  </v-app-bar>
</template>

<script>
export default {
  name: 'NavBar',
  data: () => ({
    items: [
      { name: 'en', src: require('@/assets/UKFlag.png') },
      { name: 'el', src: require('@/assets/GRFlag.png') },
    ],
  }),
  methods: {
    mail() {
      window.location.href = 'info@naxestate.com';
    },
  },
};
</script>
<style scoped>
.h1 {
  background-color: #6c6d88de;
  color: #c5dfd6;
}
</style>
