import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import i18n from './plugins/i18n';
import store from './store';

Vue.config.productionTip = false;

const firebaseConfig = {
  apiKey: 'AIzaSyAhBNG5mUiTLAYK4cKiQmrSiOT4tYmvCTs',
  authDomain: 'naxestate-70f37.firebaseapp.com',
  databaseURL: 'https://naxestate-70f37-default-rtdb.firebaseio.com',
  projectId: 'naxestate-70f37',
  storageBucket: 'naxestate-70f37.appspot.com',
  messagingSenderId: '143554591985',
  appId: '1:143554591985:web:7a95866525cf71d68dfd50',
  measurementId: 'G-88N6088CJ8',
};
firebase.initializeApp(firebaseConfig);
export const db = firebase.firestore();
export const storage = firebase.storage();
let app;
firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      router,
      vuetify,
      i18n,
      store,
      render: (h) => h(App),
    }).$mount('#app');
  }
});
