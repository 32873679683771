<template>
  <v-main fill-height id="viewProperty">
    <v-container>
      <v-card :class="margin" v-if="Object.keys(property).length">
        <v-row justify="center" wrap no-gutters>
          <v-col md="5" sm="12" cols="12">
            <v-carousel>
              <v-carousel-item
                v-for="(item, i) in property.imageUrl"
                append
                :key="i"
                reverse-transition="slide-x-reverse-transition"
                transition="slide-x-transition"
                eager
                @click="() => show(i)"
              >
                <v-img :src="property.imageUrl[i]" height="100%" eager />
              </v-carousel-item>
            </v-carousel>
            <vue-easy-lightbox
              :visible="visible"
              :index="index"
              :imgs="property.imageUrl"
              :moveDisabled="false"
              @hide="handleHide"
              style="background-color: rgba(0, 0, 0, 0.9); zoom: 140%"
            />
            <v-card-title>{{ $t('description') }}:</v-card-title>

            <v-card-text>
              {{
                `${
                  language == 'en'
                    ? property.description
                    : property.descriptionGR
                }`
              }}
            </v-card-text>
          </v-col>
          <v-col md="7" sm="12">
            <v-card-title class="headline d-flex justify-center mb-2">
              <p>
                {{ `${language == 'en' ? property.title : property.titleGR}` }}
              </p>
            </v-card-title>
            <v-card-text>
              <v-row justify="center" wrap no-gutters>
                <v-col
                  md="4"
                  sm="6"
                  class="d-flex justify-center mb-md-10 mb-sm-5"
                  v-if="property.propertyType == 'House'"
                >
                  <v-col justify="center">
                    <!-- Main Usage building size -->
                    <v-col
                      cols="12"
                      class="d-flex justify-center"
                      style="padding: 0px"
                    >
                      <v-img
                        max-width="64"
                        :src="require('@/assets/icons/mainUse.svg')"
                        contain
                      />
                    </v-col>
                    <v-col cols="12" class="d-flex justify-center">
                      <div class="my-4 text-md-h6 text-subtitle-1">
                        {{ property.mainUse }}m²
                      </div>
                    </v-col>
                    <v-col class="d-flex justify-center" style="padding: 0px">
                      {{ $t('mainUse') }}
                    </v-col>
                  </v-col>
                </v-col>
                <v-col
                  md="4"
                  sm="6"
                  class="d-flex justify-center mb-md-5 mb-sm-5"
                  v-if="property.propertyType == 'House'"
                >
                  <v-col justify="center" wrap no-gutters>
                    <!-- Secondary Usage building size -->

                    <v-col
                      cols="12"
                      class="d-flex justify-center"
                      style="padding: 0px"
                    >
                      <v-img
                        max-width="64"
                        :src="require('@/assets/icons/basement.svg')"
                        contain
                      />
                    </v-col>

                    <v-col cols="12" class="d-flex justify-center">
                      <div class="my-4 text-md-h6 text-subtitle-1">
                        {{ property.secUse }}m²
                      </div>
                    </v-col>
                    <v-col class="d-flex justify-center" style="padding: 0px">
                      {{ $t('secUse') }}
                    </v-col>
                  </v-col>
                </v-col>
                <v-col
                  md="4"
                  sm="6"
                  class="d-flex justify-center mb-md-10 mb-sm-5"
                >
                  <v-col justify="center">
                    <!-- Lot size -->
                    <v-col
                      cols="12"
                      class="d-flex justify-center"
                      style="padding: 0px"
                    >
                      <v-img
                        max-width="64"
                        :src="require('@/assets/icons/plotSize.svg')"
                        contain
                      />
                    </v-col>
                    <v-col cols="12" class="d-flex justify-center">
                      <div class="my-4 text-md-h6 text-subtitle-1">
                        {{ property.lotSize }}m²
                      </div>
                    </v-col>
                    <v-col class="d-flex justify-center" style="padding: 0px">
                      {{ $t('lotSize') }}
                    </v-col>
                  </v-col>
                </v-col>
                <!-- allowable buildable area -->
                <v-col
                  md="4"
                  sm="6"
                  class="d-flex justify-center"
                  v-if="property.propertyType == 'Plot'"
                >
                  <v-col justify="center">
                    <v-col
                      cols="12"
                      class="d-flex justify-center"
                      style="padding: 0px"
                    >
                      <v-img
                        max-width="64"
                        :src="require('@/assets/icons/allowable.svg')"
                        contain
                      />
                    </v-col>
                    <v-col cols="12" class="d-flex justify-center">
                      <div class="my-4 text-md-h6 text-subtitle-1">
                        {{ property.buildableArea }}m²
                      </div>
                    </v-col>
                    <v-col class="d-flex justify-center" style="padding: 0px">
                      {{ $t('buildableArea') }}
                    </v-col>
                  </v-col>
                </v-col>
                <!-- Adress -->
                <v-col md="4" sm="6" class="d-flex justify-center">
                  <v-col justify="center">
                    <v-col
                      cols="12"
                      class="d-flex justify-center"
                      style="padding: 0px"
                    >
                      <v-img
                        max-width="64"
                        :src="require('@/assets/icons/location.svg')"
                        contain
                      />
                    </v-col>
                    <v-col cols="12" class="d-flex justify-center">
                      <div class="my-4 text-md-subtitle-1 text-subtitle-2">
                        {{ $t(property.adress) }}, {{ $t(property.area) }}
                      </div>
                    </v-col>
                    <v-col class="d-flex justify-center" style="padding: 0px">
                      {{ $t('areaLabel') }}
                    </v-col>
                  </v-col>
                </v-col>

                <!-- Price -->
                <v-col md="4" sm="6" class="d-flex justify-center">
                  <v-col justify="center">
                    <v-col
                      cols="12"
                      class="d-flex justify-center"
                      style="padding: 0px"
                    >
                      <v-img
                        max-width="64"
                        :src="require('@/assets/icons/price.svg')"
                        contain
                      />
                    </v-col>
                    <v-col cols="12" class="d-flex justify-center">
                      <div class="my-4 text-md-h6 text-subtitle-1">
                        {{
                          property.price.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                          })
                        }}

                        €
                      </div>
                    </v-col>
                    <v-col class="d-flex justify-center" style="padding: 0px">
                      {{ $t('Price') }} €
                    </v-col>
                  </v-col>
                </v-col>
                <!-- Additional info for the property -->
                <v-col md="4" sm="6" class="d-flex justify-center">
                  <v-col justify="center">
                    <v-col
                      cols="12"
                      class="d-flex justify-center"
                      style="padding: 0px"
                    >
                      <v-img
                        max-width="64"
                        :src="require('@/assets/icons/additional.svg')"
                        contain
                      />
                    </v-col>
                    <v-col cols="12" class="d-flex justify-center">
                      <div class="my-4 text-md-subtitle-1 text-subtitle-2">
                        {{
                          `${
                            language == 'en'
                              ? property.additional
                              : property.additionalGR
                          }`
                        }}
                      </div>
                    </v-col>
                    <v-col class="d-flex justify-center" style="padding: 0px">
                      {{ $t('additionalInfo') }}
                    </v-col>
                  </v-col>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider class="mx-4"></v-divider>
          </v-col>
          <v-col offset="10" v-if="isLoggedIn" class="mb-1">
            <v-btn color="accent" to="/edit">edit</v-btn>
            <v-btn class="ml-2" color="secondary">delete</v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <v-row justify="center" wrap no-gutters>
      <v-card>
        <v-card-text
          class="text-md-subtitle-1 text-subtitle-2 text-sm-caption d-flex justify-center"
        >
          <div style="white-space: pre-line">
            {{
              `${language == 'en' ? property.extended : property.extendedGR}`
            }}
          </div>
        </v-card-text></v-card
      >
    </v-row>
    <!-- preview tabs -->
    <v-container v-if="margin === 'ma-10'">
      <Featured />
    </v-container>
  </v-main>
</template>

<script>
import Featured from '../components/FeaturedItem.vue';
import firebase from 'firebase';
import VueEasyLightbox from 'vue-easy-lightbox';
export default {
  components: { Featured, VueEasyLightbox },
  name: 'viewProperty',
  data() {
    return {
      visible: false,
      index: 0,
    };
  },

  computed: {
    margin() {
      if (this.$vuetify.breakpoint.mobile) return 'ma-2';
      else return 'ma-10';
    },
    property() {
      const property = this.$store.getters.viewClicked;
      return property;
    },
    language() {
      return this.$i18n.locale;
    },
    isLoggedIn() {
      return firebase.auth().currentUser;
    },
  },
  methods: {
    show(index) {
      this.index = index;
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
      this.index = 0;
    },
  },

  watch: {
    property: {
      immediate: true,
      handler(value) {
        if (Object.keys(value).length == 0) {
          this.$store.dispatch('findBookmarked', {
            id: this.$route.params.id,
          });
        }
      },
    },
  },
};
</script>

<style scoped>
#viewProperty {
  background-color: rgb(223, 243, 239) !important;
  color: #571a1a;
}
</style>
